exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-all-qr-js": () => import("./../../../src/pages/all-qr.js" /* webpackChunkName: "component---src-pages-all-qr-js" */),
  "component---src-pages-bus-scan-js": () => import("./../../../src/pages/bus-scan.js" /* webpackChunkName: "component---src-pages-bus-scan-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-qr-scan-js": () => import("./../../../src/pages/qr-scan.js" /* webpackChunkName: "component---src-pages-qr-scan-js" */),
  "component---src-pages-report-js": () => import("./../../../src/pages/report.js" /* webpackChunkName: "component---src-pages-report-js" */),
  "component---src-pages-session-complete-js": () => import("./../../../src/pages/session-complete.js" /* webpackChunkName: "component---src-pages-session-complete-js" */)
}

